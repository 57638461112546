//window.cookieconsent_options = { "message": "Denna webbplats använder cookies (kakor) för att ge dig bästa möjliga upplevelse.", "dismiss": "OK", "learnMore": "Läs mer.", "link": "/", "theme": "dark-bottom" };


//Loacalization
var localizedStrings;
var locale = $("html").attr("lang");

(function () {
    
    localizedStrings = {
        cookieMessage: {
            'en-GB': 'We use cookies to track usage and preferences. If you continue, it means that you accept the use of cookies',
            'en-US': 'We use cookies to track usage and preferences. If you continue, it means that you accept the use of cookies',
            'sv-SE': 'Vi anv&auml;nder cookies f&ouml;r att ge dig b&auml;sta m&ouml;jliga upplevelse och funktion. Om du forts&auml;tter inneb&auml;r det att du accepterar att cookies anv&auml;nds.'
        },
        cookieMore: {
            'en-GB': 'Read more.',
            'en-US': 'Read more.',
            'sv-SE': 'Läs mer.'
        },
        cookieInfoPage : {
            'en-GB': '/about-cookies/',
            'en-US': '/about-cookies/.',
            'sv-SE': '/om-cookies/'
        },
        nextstepLabel: {
            'en-GB': 'Next',
            'en-US': 'Next',
            'sv-SE': 'Nästa'
        },
        previousstepLabel: {
            'en-GB': 'Previous',
            'en-US': 'Previous',
            'sv-SE': 'Föregående'
        },
        thanksMessage: {
            'en-GB': 'Thanks for your enquiry',
            'en-US': 'Thanks for your inquiry',
            'sv-SE': 'Tack för din förfrågan. Vi kontaktar dig inom kort.'
            
        },
        colorboxCurrent: {
            'en-GB': 'image {current} of {total}',
            'en-US': 'image {current} of {total}',
            'sv-SE': 'bild {current} av {total}'

        },
        error: {
            'en-GB': 'Error',
            'en-US': 'Error',
            'sv-SE': 'Fel'

        }
    }
   

    window.cookieconsent_options = {
        "message": localizedStrings['cookieMessage'][locale],
        "dismiss": "OK",
        "learnMore": localizedStrings['cookieMore'][locale],
        "link": localizedStrings['cookieInfoPage'][locale],
        "theme": "dark-bottom"
    };

})();


$(document).ready(function () {

    /* INIT COLORBOX */
    $('a.gallery').colorbox({ rel: 'group1', current: localizedStrings['colorboxCurrent'][locale], maxWidth: '90%', maxHeight: '90%' });


    /* INIT MASONRY */
    var $grid = $('.grid').masonry({
        itemSelector: '.grid-item',
        percentPosition: true,
        columnWidth: '.grid-sizer'
    });

    // layout Isotope after each image loads
    $grid.imagesLoaded().progress(function () {
        $grid.masonry();
    });



});






/* START FORM */
/* För att form-group ska få rätt class vid error */
$.validator.setDefaults({
    highlight: function (element) {
        $(element).closest('.form-group').addClass('has-error');
    },
    unhighlight: function (element) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function (error, element) {
        if (element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    }
});

/* För att binda custom controll till unotrubsive */
$.validator.addMethod("mustbetrue", function (value, element, param) {
    return element.checked;
});
$.validator.unobtrusive.adapters.addBool("mustbetrue");


$.validator.addMethod("genericremote", function (value, element, param) {
    console.log(value);
    console.log(element.name);
    console.log(param);
    return false;
});
$.validator.unobtrusive.adapters.addBool("genericremote");


/* Resultat av Ajax formulär */
function ShowResult(info) { 
    if (info == undefined) {
        $("#status").removeClass();
        $("#status").addClass("alert alert-danger");
        $("#status").html("<strong>Fel!</strong> Ett tekniskt fel inträffade. Vänligen försök igen eller kontakta support.<br><strong>Error!</strong> A technical error occurred. Please try again or contact support.");
        //Or redirect to error page window.location.href = "urlToRedirectPage";

    } else {
        if (info.success == true) {
            if (info.redirectPage != undefined && info.redirectPage != "") {
                window.location.href = info.redirectPage;
            } else {
                $("#target").removeClass();
                $("#target").addClass("alert alert-success");
                $("#target").html(info.message);
            }
        } else {
            if (info.redirectPage != undefined && info.redirectPage != "") {
                window.location.href = info.redirectPage;
            } else {
                $("#status").removeClass();
                $("#status").addClass("alert alert-danger");
                $("#status").html(info.message);
            }
        }

    }

}

$('#contactform').submit(function () {
    //$("#send").hide(); 
});

/* END FORM */



$('button[data-loading-text]')
    .on('click', function () {
        var btn = $(this)
        btn.button('loading')
        setTimeout(function () {
            btn.button('reset')
        }, 3000)
    });


//INQUIRY FORM
var App = {};


(function ($, App, localstrings, culture) {

    $.wizard.test();

    $(".wizard").wizard({ nextstepLabel: localstrings['nextstepLabel'][culture], previousstepLabel: localstrings['previousstepLabel'][culture] });


    $.datetimepicker.setLocale('sv');
    $(".datetimepicker").datetimepicker({ format: "Y-m-d", dayOfWeekStart: 1, timepicker: false});

    var scopeOnChange = function (elem) {
        var $this = $(elem);


        //If .wizard exist do it step by step 
        if ($(".wizard").length > 0) {
            var currentStep = $this.closest(".wizard-step");
            var predefinedNext = $this.data("data-next");

            switch ($this.val()) {
                case "Enkel":
                    currentStep.data("next", 3)
                    break;
                case "Forfogande":
                    currentStep.data("next", 2)
                    break;
                default:
                    currentStep.data("next", predefinedNext == undefined ? 1 : predefinedNext);
            }

        }
            //Else hide show
        else {

            $('.hideable-step').hide();

            switch ($this.val()) {
                case "Enkel":
                    $('.hideable-step:eq(2)').show()
                    break;
                case "Forfogande":
                    $('.hideable-step:eq(1)').show()
                    break;
                default:
                    $('.hideable-step:eq(0)').show()
            }

        }




    }

    scopeOnChange($("#Scope"));

    $("#Scope").change(function (e) {
        scopeOnChange(e.target)
    });



    App.inquiryFormSuccessHandler = function (data) {
        App.showAlert(localstrings['thanksMessage'][culture], "success", "#contactform");
        $("#contactform")[0].reset();

    };

    App.inquiryFormFailedHandler = function (httpResponse) {
        App.showModal(localstrings['error'][culture], httpResponse.responseJSON.message, { dismissButton: { type: "default", label: "OK" } });
    };

    App.showAlert = function(message, type, targetSelector) {
        var model = $("" +
            "<div class=\"alert alert-" + type + " fade in\">" +
            "    <a class=\"close\" data-dismiss=\"alert\">&times;</a>" +
            "    <span>" + message + "</span>" +
            "</div>");

        $(targetSelector).append(model);

        setTimeout(function () { 
            model.alert("close");
        }, 10000);
    };


    App.showModal = function (title, message, options) {

        var defaults = {
            id: "modal1",
            dismissButton: {
                type: "default",
                label: "Dismiss"
            },
            additionalbuttons: []
        };

        var settings = $.extend(defaults, options);

        var model = $("" +
            "<div class=\"modal fade\" id=\"" + settings.id + "\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\">" +
            "  <div class=\"modal-dialog\" role=\"document\">" +
            "    <div class=\"modal-content\">" +
            "      <div class=\"modal-header\">" +
            "        <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>" +
            "        <h4 class=\"modal-title\" id=\"myModalLabel\">" + title + "</h4>" +
            "      </div>" +
            "      <div class=\"modal-body\">" +
                        message +
            "      </div>" +
            "      <div class=\"modal-footer\">" +
            "        <button type=\"button\" class=\"btn btn-" + settings.dismissButton.type + "\" data-dismiss=\"modal\">" + settings.dismissButton.label + "</button>" +
            "      </div>" +
            "    </div>" +
            "  </div>" +
            "</div>");

        $("body").append(model);
        model.modal();

    };



})(jQuery, App, localizedStrings, locale);

