; (function ($, window, document, undefined) {

    var pluginName = "wizard",
        defaults = {
            stepSelector: ".wizard-step",
            nextstepLabel: "Nästa",
            previousstepLabel: "Föregående",
            translate: function (key) {
                return key;
            }
        };


    function Plugin(element, options) {
        this.element = element;
        
        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    Plugin.prototype = {

        init: function () {

            var base = this;

            $.validator.setDefaults({
                ignore: [],
                showErrors: function (errorMap, errorList) {
                    if (errorList.length > 0) {
                        base.gotoField($(this.currentForm), $(errorList[0].element));
                    }
                    this.defaultShowErrors();
                }
            });


            var steps = base.getSteps(this.element);
            

            steps.each(function (index, element) {
                var btnPrev = $("<a class=\"btn btn-primary\" style=\"margin-right:20px\">" + base.options.previousstepLabel + "</a>");
                var btnNext = $("<a class=\"btn btn-primary\">" + base.options.nextstepLabel + "</a>");

                var buttons = $("<div class=\"col-md-12 text-center\" style=\"margin-top:20px;\"></div>")

                $(element).append(buttons);

                if (index != 0) {
                    buttons.append(btnPrev);
                };

                if (index != steps.length - 1) {
                    buttons.append(btnNext);
                } else {
                    buttons.append($(".btn-submit"));
                }

                btnNext.click(function () {
                    var next = base.getNextStepIndex(element, index);
                    base.gotoStep(base.element, next, index);
                });


                btnPrev.click(function () {
                    var prev = base.getPrevStepIndex(element, index);
                    base.gotoStep(base.element, prev);
                });

            });

            base.gotoStep(this.element, 0);


        },


        getSteps: function (el) {
            return $(el).find(this.options.stepSelector);
        },

        gotoStep: function (el, targetIndex, currentIndex) {
            this.getSteps(el).each(function (index, element) {
                if (index == targetIndex) {
                    $(element).fadeIn();
                    if (!isNaN(currentIndex)) {
                        $(element).data("prev", currentIndex);
                    }
                } else {
                    $(element).hide();
                }
            });
            this.updateProgressBar(el, targetIndex);
        },

        gotoField: function(el, targetField){
            var targetElement = $(targetField);
            var targetStep = targetElement.closest(this.options.stepSelector);

            var stepIndex = this.getSteps(el).index(targetStep);
            this.gotoStep(el, stepIndex);

            targetElement.focus();
        },

        getProgressBar: function (el) {
            var steps = this.getSteps(el);
            var stepPercent = 100 / steps.length;

            var progressBar = $(el).find(".progress-bar");

            if (progressBar.length) {
                progressBar.first().data("stepsize", stepPercent);
                return progressBar.first();
            }


            var progressContainer = $("<div class=\"progress\"></div>");
            progressBar = $("<div class=\"progress-bar\" style=\"width:0%;\"></div>");
            progressBar.data("stepsize", stepPercent);

            progressContainer.append(progressBar);

            $(el).prepend(progressContainer);

            return progressBar;
        },

        updateProgressBar: function (el, index) {
            var progressBar = this.getProgressBar(el);
            var width = ((parseInt(index) + 1) * progressBar.data("stepsize"));
            progressBar.css({ width: width + "%" });
        },

        getPrevStepIndex: function (step, currentIndex) {
            var prev = $(step).data("prev");
            if (prev == undefined) {
                prev = currentIndex - 1;
            };
            return prev;
        },

        getNextStepIndex: function (step, currentIndex) {
            var next = $(step).data("next");
            if (next == undefined) {
                next = currentIndex + 1;
            };
            return next;
        }
    };

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName,
                new Plugin(this, options));
            }
        }); 
    };

    $[pluginName] = {
        test: function () {
            //console.log("test");
        }
    };

})(jQuery, window, document);